.Footer {
  background-color: #343a40;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.Footer-text {
  color: white;
  font-size: 12px;
}

.Footer-logo {
  height: 40vmin;
  pointer-events: none;
}

.Footer-header {
  background-color: #0f2144;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Footer-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
