.Contact-left-align {
  text-align: left;
}

.contact-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.contact-full-width {
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
