.Navigation {
  text-align: center;
}

.Navigation-logo {
  height: 40vmin;
  pointer-events: none;
}

.Navigation-header {
  background-color: #0f2144;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Navigation-link {
  color: #61dafb;
}

.navbar-nav > li{
  padding-left:3px;
  padding-right:3px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
