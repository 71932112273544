.Portfolio-left-align {
  text-align: left;
}

.Portfolio-text-bold-red {
  color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
